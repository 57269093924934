import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import Comments from "../components/Comments";
import { Labels, determineLabels } from "../components/intakeLabels";

const cloudinaryEndpoint =
  "https://res.cloudinary.com/stray-cat-blues/image/upload/";

const useStyles = makeStyles((theme) => ({
  fixedHeight: {},
  root: {
    maxWidth: 440,
    height: "fit-content",
  },
  section: {
    margin: theme.spacing(1),
  },
  fullSize: {
    width: "100%",
    overflowWrap: "break-word",
    marginLeft: "auto",
    marginRight: "auto",
  },
  itemHeader: {
    color: theme.palette.primary.main,
  },
  container: {
    marginTop: "20px",
  },
}));

const injectCloudinaryFilters = (url) => {
  return url.replace(
    cloudinaryEndpoint,
    `${cloudinaryEndpoint}h_300,f_auto,q_auto/`
  );
};

export default function Collection(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} container direction="row-reverse"></Grid>
      </Grid>
      {props.isLoaded && props.items && (
        <Grid container spacing={3} className={classes.container}>
          {props.items.map((row) => (
            <Grid
              key={row.id}
              item
              xs={12}
              md={3}
              lg={4}
              container
              direction="row"
              justify="flex-start"
            >
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0", timeout: 1000 }}
              >
                <Card
                  raised={true}
                  className={clsx(classes.root, classes.fullSize)}
                >
                  <CardActionArea
                    component={Link}
                    to={"/" + props.link_type + "/" + row.id}
                    target={props.newTab ? "_blank" : undefined}
                    rel={props.newTab ? "noreferrer" : undefined}
                  >
                    {((row.images && row.images[0]) || row.image) && (
                      <CardMedia
                        component="img"
                        alt={row.title}
                        className={classes.fixedHeight}
                        src={
                          row.image
                            ? injectCloudinaryFilters(row.image)
                            : injectCloudinaryFilters(row.images[0])
                        }
                        title={row.title}
                      />
                    )}
                    <CardContent>
                      <div className={classes.section}>
                        <Grid container>
                          <Grid item xs>
                            <Typography gutterBottom variant="h4">
                              {row.name || row.title}
                            </Typography>
                          </Grid>
                        </Grid>
                        {props.fields.map((item) => (
                          <div
                            key={row[item] + row.id}
                            className={classes.section}
                          >
                            <Typography
                              variant="overline"
                              display="block"
                              className={classes.itemHeader}
                              gutterBottom
                            >
                              {item !== "intake_date" &&
                                item.replace(/_/g, " ")}
                              {item === "intake_date" && "Request Date"}
                            </Typography>
                            {item === "intake_date" &&
                              new Date(row[item] + "Z").toLocaleString()}
                            {item !== "intake_date" &&
                              row[item].toString().toLocaleUpperCase()}
                          </div>
                        ))}
                        {row.comments && row.comments.length > 0 && (
                          <div className={classes.section}>
                            <Typography
                              variant="overline"
                              display="block"
                              className={classes.itemHeader}
                              gutterBottom
                            >
                              Comments
                            </Typography>
                            <Comments
                              comments={row.comments.slice(0, 2)}
                              removeAvatar={true}
                              removeNewComment={true}
                              short={true}
                            />
                          </div>
                        )}
                        <Labels labels={determineLabels(row)} />
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grow>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
