import { useAuth0 } from "@auth0/auth0-react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import Comments from "../components/Comments";
import Loading from "../components/Loading";
import PageTemplate from "../components/PageTemplate";
import PhotoGalleryDisplay from "../components/Photos";
import { Error } from "../components/catFails";
import {
  Assignments,
  SubscribeDialog,
  TitleEditor,
} from "../components/intakeClaims";
import { Labels, determineLabels } from "../components/intakeLabels";
import EmailDialog from "../components/sendEmail";

const cloudinaryEndpoint =
  "https://res.cloudinary.com/stray-cat-blues/image/upload/";

// todo when this is running locally I will do this all better.
const injectCloudinaryFilters = (images) => {
  return images?.map((image) =>
    image.replace(
      cloudinaryEndpoint,
      `${cloudinaryEndpoint}h_700,f_auto,q_auto/`
    )
  );
};

const useStyles = makeStyles((theme) => ({
  margins: {
    "& > *": {
      margin: theme.spacing(3),
    },
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  padding: {
    paddingTop: theme.spacing(4),
  },
  fit: {
    padding: theme.spacing(2),
  },
  itemHeader: {
    color: theme.palette.primary.main,
  },
  accordion: {
    padding: theme.spacing(1),
    borderRadius: "4px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    marginRight: theme.spacing(0),
    zIndex: 4,
  },
  bottomMargin: {
    marginBottom: theme.spacing(4),
  },
}));

const IntakeView = () => {
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isSubscribed, setIsSubscribed] = React.useState(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const [initialValues, setInitialValues] = React.useState(null);
  const [comments, setComments] = React.useState([]);
  const [subscribers, setSubscribers] = React.useState([]);
  const [assignments, setAssignments] = React.useState({
    outreach: [],
    fosters: [],
    trappers: [],
  });
  //const topRef = useRef(null);
  const executeScroll = () => document.getElementById("top")?.focus(); //topRef.current.scrollIntoView();
  const [labels, setLabels] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const [images, setImages] = React.useState([]);
  let { intakeId } = useParams();
  const keyMap = {
    intake_date: "Help Request Date",
    submitted_by: "Submitted By",
    phone: "Phone Number",
    email: "Email Address",
    address: "Address",
    cats_address: "Location of cat(s) if different than your address",
    county: "County",
    cats_found_at: "Where did you find these cat(s)?",
    injury_details: "If a cat is injured, please provide details",
    cats_description:
      "Description of the cat(s) - gender (if known), color, markings, hair length",
    number_of_kittens: "Number of kittens ( 0 - 6 months old )",
    number_of_young: "Number of young cats ( 6 months - 3 years old )",
    number_of_adults: "Number of adult cats ( 3 years - 8 years old )",
    number_of_seniors: "Number of senior cats ( > 8 years old )",
    cats_inside: "Are the cat(s) inside?",
    can_put_in_carrier: "Are you able to get the cat(s) into a pet carrier?",
    needs_bottle_feed: "Do the cat(s) need to be bottle fed?",
    litter_trained: "Are the cat(s) litter trained?",
    friendly: "Are the cat(s) friendly?",
  };

  async function handleSend(values) {}

  async function updateTitle(values) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      const body = {
        title: values["title"],
      };
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        method: "PATCH",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setTitle(result.title);
          },
          (error) => {
            setError(error.toString());
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  }

  async function updateLabels(values) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      const body = {
        needs_trap: values["needs_trapper"] ? "true" : "false",
        needs_foster: values["needs_foster"] ? "true" : "false",
        status: values["status"],
      };
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        method: "PATCH",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            setLabels(determineLabels(result));
          },
          (error) => {
            setError(error.toString());
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  }

  async function addAssignment(assignmentType) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      const assignmentTypeLookup = {
        fosters: "foster",
        trappers: "trapper",
        outreach: "outreach",
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}/assign`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assignment_type: assignmentTypeLookup[assignmentType],
          }),
          method: "POST",
        }
      ).then(
        (response) => {
          if (!response.ok) {
            setError(`Failed to change ${assignmentType}`);
          } else {
            setAssignments({
              ...assignments,
              [assignmentType]: [
                ...assignments[assignmentType],
                user.sub.split("|")[1],
              ],
            });
          }
        },
        (error) => {
          setError(error.toString());
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  async function removeAssignment(assignmentType) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      const assignmentTypeLookup = {
        fosters: "foster",
        trappers: "trapper",
        outreach: "outreach",
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}/unassign`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assignment_type: assignmentTypeLookup[assignmentType],
          }),
          method: "POST",
        }
      ).then(
        (response) => {
          if (!response.ok) {
            setError(`Failed to change ${assignmentType}`);
          } else {
            setAssignments({
              ...assignments,
              [assignmentType]: assignments[assignmentType].filter(
                (u) => u !== user.sub.split("|")[1]
              ),
            });
          }
        },
        (error) => {
          setError(error.toString());
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  async function handleSubscription() {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      const action = isSubscribed ? "unsubscribe" : "subscribe";
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}/${action}`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      ).then(
        () => {
          setIsSubscribed(!isSubscribed);
          if (action === "unsubscribe") {
            setSubscribers(
              subscribers.filter((sub) => sub !== user.sub.split("|")[1])
            );
          } else {
            setSubscribers([...subscribers, user.sub.split("|")[1]]);
          }
        },
        (error) => {
          setError(error.toString());
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  async function updateImages(values) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          photos: [...images, ...values],
        }),
        method: "PATCH",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setImages(injectCloudinaryFilters(result.images));
          },
          (error) => {
            setIsLoaded(true);
            setError(error.toString());
          }
        );
    } catch (e) {
      setError(e.toString());
      console.log(e.message);
    }
  }

  async function deleteImage(image) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          photos: images.filter((i) => i !== image),
        }),
        method: "PATCH",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setImages(injectCloudinaryFilters(result.images));
          },
          (error) => {
            setIsLoaded(true);
            setError(error.toString());
          }
        );
    } catch (e) {
      setError(e.toString());
      console.log(e.message);
    }
  }
  const boolMap = (b) => {
    switch (b) {
      case true:
        return "Yes";
      case false:
        return "No";
      case null:
        return "Not specified";
      case undefined:
        return "Not specified";
      case "":
        return "Not specified";
      default:
        return b.toString();
    }
  };

  React.useEffect(() => {
    const getIntake = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_BACKEND_AUDIENCE,
        });
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}`,
          {
            headers: { Authorization: `bearer ${accessToken}` },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setAssignments(result.assignments);
              setInitialValues(result);
              setTitle(result.title);
              setComments(result.comments.reverse());
              setImages(injectCloudinaryFilters(result.images));
              setIsSubscribed(result.subscribed);
              setSubscribers(result.subscriptions);
              const l = determineLabels(result);
              setLabels(l);
              delete result.assignments;
              delete result.comments;
            },
            (error) => {
              setIsLoaded(true);
              setError(error.toString());
            }
          );
      } catch (e) {
        setError(e.toString());
        console.log(e.message);
      }
    };
    getIntake();
  }, []);

  return (
    <PageTemplate>
      {isLoaded && !error && initialValues && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <div className={classes.bottomMargin}>
              {labels && (
                <Labels
                  editButtonId="top"
                  labels={labels}
                  setLabels={setLabels}
                  updateLabels={updateLabels}
                />
              )}
            </div>
            <Grid item xs={12} md={12} lg={12}>
              <TitleEditor
                title={title}
                setTitle={setTitle}
                updateTitle={updateTitle}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isLoaded && <Loading style={{ backgroundColor: "#f2f7f9" }} />}
      {!error && initialValues && (
        <Grid spacing={3} className={classes.padding} container>
          <Grid item xs={12} md={12} lg={12} className={classes.fit}>
            <PhotoGalleryDisplay
              images={images}
              setPhotos={(i) => {
                updateImages(i);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.padding}>
            <Paper elevation={5} className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="h5">PEOPLE</Typography>
                <EmailDialog intakeId={intakeId} />
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12} md={12} lg={12}>
                  <Assignments
                    username={user.sub}
                    assignments={assignments}
                    addAssignment={addAssignment}
                    removeAssignment={removeAssignment}
                  />
                  <SubscribeDialog
                    intakeId={intakeId}
                    isSubscribed={isSubscribed}
                    followers={subscribers}
                    handleSubscription={handleSubscription}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Accordion
              elevation={5}
              defaultExpanded
              className={classes.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panel1b-header"
              >
                <Typography variant="h5">DETAILS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {Object.entries(keyMap).map(([key, value]) => (
                    <Grid key={key} item xs={12} md={12} lg={12}>
                      <Grid container className={classes.fit}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            className={classes.itemHeader}
                            gutterBottom
                            variant="h5"
                          >
                            {value}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="body1">
                            {key === "intake_date" &&
                              new Date(
                                initialValues[key] + "Z"
                              ).toLocaleString()}
                            {key === "email" && (
                              <a href={"mailto:" + boolMap(initialValues[key])}>
                                {boolMap(initialValues[key])}
                              </a>
                            )}
                            {key !== "intake_date" &&
                              key !== "email" &&
                              boolMap(initialValues[key])}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={5}
              defaultExpanded
              className={classes.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panel1b-header"
              >
                <Typography variant="h5">COMMENTS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Comments
                  key={"comments-" + intakeId}
                  comments={comments}
                  intakeId={intakeId}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  setIsLoaded={setIsLoaded}
                  setError={setError}
                  setComments={setComments}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
      {isLoaded && error && <Error error={error} />}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="top"
        className={classes.fab}
        onClick={executeScroll}
        size={"medium"}
      >
        <ArrowUpwardIcon />
        Top
      </Fab>
    </PageTemplate>
  );
};

export default IntakeView;
