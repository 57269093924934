import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForward } from "@material-ui/icons";
import CalendarMonthIcon from "@material-ui/icons/CalendarToday";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InputIcon from "@material-ui/icons/Input";
import LinkIcon from "@material-ui/icons/Link";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import logo from "../assets/logo.svg";
import LogoutButton from "./Logout";
import { useAuth0 } from "@auth0/auth0-react";

const drawerWidth = 390; // Temp size while we have the Available from trappers link - old size was 240;
const drawerHeight = 64;
const useStyles = makeStyles((theme) => ({
  drawer: {
    height: "100% !important",
    marginTop: `calc(${drawerHeight}px + 6px)`,
    maxWidth: 400,
    //backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  root: {
    display: "flex",
  },
  toolbar: {
    //paddingRight: 24, // keep right padding when drawer closed
    flexWrap: "wrap",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 12,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  logo: {
    height: "64px",
    minWidth: "100px",
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginTop: "3px",
  },
  scbWebsiteLink: {
    display: "flex",
    alignItems: "center",
    marginRight: "30px",
  },
  scbWebsiteLinkColor: {
    color: "midnightblue",
  },
  scbWebsiteLinkIcon: {
    fontSize: "1rem",
  },
}));

export default function Navigation() {
  const classes = useStyles();

  const { user } = useAuth0();
  const userGmail = user.sub.split("google-apps|")[1];
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  return (
    <div>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            className={clsx(classes.menuButton)}
            onClick={() => setIsDrawerOpen(isDrawerOpen === false)}
          >
            <MenuIcon />
          </IconButton>
          <div className={clsx(classes.logo)}></div>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          ></Typography>
          <div style={{ "margin-right": "10px" }}>
            <Typography variant="button">
              <Link
                underline="always"
                href="https://straycatblues.org/"
                target="_blank"
                rel="noopener" /* Open in new tab */
                className={clsx(classes.scbWebsiteLinkColor)}
              >
                <div className={clsx(classes.scbWebsiteLink)}>
                  Stray Cat Blues Website
                  {<ArrowForward className={{ fontSize: 16 }} />}
                </div>
              </Link>
            </Typography>
          </div>
          <div style={{ "margin-right": "10px" }}>
            <Typography variant="button">
              <Link
                underline="always"
                href="https://forms.gle/eDaRSMufuzQ6sWgk6"
                target="_blank"
                rel="noopener" /* Open in new tab */
                className={clsx(classes.scbWebsiteLinkColor)}
              >
                <div className={clsx(classes.scbWebsiteLink)}>
                  Report a Portal Error/Feature
                  {<ArrowForward className={{ fontSize: 16 }} />}
                </div>
              </Link>
            </Typography>
          </div>
          <Typography variant="h6">
            <LogoutButton />
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Navigation Tabs */}
      <Drawer
        variant="temporary"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: clsx(classes.drawerPaper),
        }}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <List>
          <ListItem
            button
            component={Link}
            href="https://animalsfirst.com/shelter/stray-cat-blues"
            target="_blank"
            rel="noopener"
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Animals First" />
          </ListItem>
          <ListItem button component={ReactRouterLink} to="/calendars">
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="Calendars" />
          </ListItem>
          <ListItem button component={ReactRouterLink} to="/">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem 
            button
            component={Link}
            href="https://docs.google.com/document/d/1wtyrEBLS6WxlRIsz9mgCojYLFUg9pMlM/edit?usp=sharing&ouid=104906244409542961063&rtpof=true&sd=true"
            target="_blank"
            rel="noopener">
            <ListItemIcon>
              <InputIcon />
            </ListItemIcon>
            <ListItemText primary="AVAILABLE KITTENS FROM TRAPPERS" />
          </ListItem>
          <ListItem button component={ReactRouterLink} to="/intakes">
            <ListItemIcon>
              <InputIcon />
            </ListItemIcon>
            <ListItemText primary="Help Requests" />
          </ListItem>
          <ListItem
            button
            component={Link}
            href="https://meds.straycatblues.org/login"
            target="_blank"
            rel="noopener"
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Medication Requests" />
          </ListItem>
          <ListItem
            button
            component={Link}
            href="https://docs.google.com/document/d/1N9nmkxhoZmTO4RCAtn_qE6nSDOZFZ4XWQZGJhwjX1MI/edit"
            target="_blank"
            rel="noopener"
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="New Foster Packet" />
          </ListItem>
          <ListItem button component={ReactRouterLink} to="/partners">
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Partners" />
          </ListItem>
          <ListItem
            button
            component={Link}
            href={`https://drive.google.com/drive/u/0/folders/0ACOjqjTdZiU1Uk9PVA`}
            target="_blank"
            rel="noopener"
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="SCB Google Drive" />
          </ListItem>
          <ListItem button component={ReactRouterLink} to="/vets">
            <ListItemIcon>
              <LocalHospitalIcon />
            </ListItemIcon>
            <ListItemText primary="Veterinarians" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
