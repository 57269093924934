import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import CloudinaryButton from "../components/photoUploader";

const useStyles = makeStyles((theme) => ({
  camera: {
    alignSelf: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  row: {
    flexDirection: "row",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  fullWidth: {
    width: "100%",
  },
  center: {
    textAlign: "center",
  },
}));
export default function IntakeView() {
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState(null);
  
  const queryParameters = new URLSearchParams(window.location.search)
  const intakeType = queryParameters.get("intake_type") ?? '';
  console.log(`Intake type: `, intakeType);

  function getBoolean(v) {
    switch (v) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return null;
    }
  }

  async function postIntake(values) {
    try {
      console.log(`Intake type: `, intakeType, ' URL: ', `${process.env.REACT_APP_BACKEND_URL}/intakes?intake_type=${intakeType}`);

      await fetch(`${process.env.REACT_APP_BACKEND_URL}/intakes?intake_type=${intakeType}`, {
        method: "POST",
        body: JSON.stringify({
          address: [
            values.address,
            values.city,
            values.state,
            values.zip_code,
          ].join(" "),
          can_put_in_carrier: getBoolean(values.can_put_in_carrier),
          cats_address: values.cats_address,
          cats_description: values.cats_description,
          cats_found_at: values.cats_found_at,
          cats_inside: getBoolean(values.cats_inside),
          county: values.county,
          email: values.email,
          friendly: getBoolean(values.friendly),
          injury_details: values.injury_details,
          litter_trained: getBoolean(values.litter_trained),
          needs_bottle_feed: getBoolean(values.needs_bottle_feed),
          needs_trap: getBoolean(values.needs_trap),
          number_of_adults: values.number_of_adults,
          number_of_kittens: values.number_of_kittens,
          number_of_seniors: values.number_of_seniors,
          number_of_young: values.number_of_young,
          phone: values.phone,
          photos: values.photos,
          submitted_by: values.submitted_by,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            window.top.location.href =
              "https://straycatblues.org/found-a-cat-thank-you-page";
          },
          (error) => {
            setError(error.toString());
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div className={classes.fullWidth}>
      <Formik
        initialValues={
          initialValues || {
            address: "",
            can_put_in_carrier: null,
            cats_address: "",
            cats_description: "",
            cats_found_at: "",
            cats_inside: null,
            city: "",
            county: "",
            email: "",
            friendly: null,
            injury_details: "",
            litter_trained: null,
            needs_bottle_feed: null,
            needs_trap: null,
            number_of_adults: 0,
            number_of_kittens: 0,
            number_of_seniors: 0,
            number_of_young: 0,
            phone: "",
            photos: [],
            state: "",
            submitted_by: "",
            zip_code: "",
          }
        }
        validationSchema={Yup.object().shape({
          address: Yup.string().required("Required"),
          can_put_in_carrier: Yup.string().required("Required"),
          cats_address: Yup.string().required("Required"),
          cats_description: Yup.string().required("Required"),
          cats_inside: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
          county: Yup.string().required("Required"),
          email: Yup.string().email().required("Required"),
          friendly: Yup.string().required("Required"),
          litter_trained: Yup.string().required("Required"),
          needs_trap: Yup.string().required("Required"),
          phone: Yup.string().required("Required").max(10).min(10),
          state: Yup.string().required("Required"),
          submitted_by: Yup.string().required("Required"),
          zip_code: Yup.string().required("Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await postIntake(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={(event) => handleSubmit}>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="submitted_by"
                  id="name-text-outlined"
                  label="Name"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  error={errors.submitted_by && touched.submitted_by}
                  helperText={
                    errors.submitted_by &&
                    touched.submitted_by &&
                    errors.submitted_by
                  }
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="phone"
                  id="phone-text-outlined"
                  label="Phone number"
                  variant="outlined"
                  pattern="^[0-9]*$"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  error={errors.phone && touched.phone}
                  helperText={errors.phone && touched.phone && errors.phone}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="email"
                  id="email-text-outlined"
                  label="Email Address"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="address"
                  id="address-text-outlined"
                  label="Address"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  error={errors.address && touched.address}
                  helperText={
                    errors.address && touched.address && errors.address
                  }
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="city"
                  id="city-text-outlined"
                  label="City"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city && errors.city}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="county"
                  id="county-text-outlined"
                  label="County"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  error={errors.county && touched.county}
                  helperText={errors.county && touched.county && errors.county}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="state"
                  id="state-text-outlined"
                  label="State"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  required={true}
                  error={errors.state && touched.state}
                  helperText={errors.state && touched.state && errors.state}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  name="zip_code"
                  id="zip-text-outlined"
                  label="Zip/Postal Code"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zip_code}
                  required={true}
                  error={errors.zip_code && touched.zip_code}
                  helperText={
                    errors.zip_code && touched.zip_code && errors.zip_code
                  }
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  multiline={true}
                  id="cats-address-text-outlined"
                  name="cats_address"
                  label="Location of cat(s) if different than your address"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cats_address}
                  required={true}
                  error={errors.cats_address && touched.cats_address}
                  helperText={
                    errors.cats_address &&
                    touched.cats_address &&
                    errors.cats_address
                  }
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  multiline={true}
                  name="cats_found_at"
                  id="cats-found-at-text-outlined"
                  label="Where did you find these cat(s)?"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cats_found_at}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  multiline={true}
                  id="injury-details-text-outlined"
                  name="injury_details"
                  label="If a cat is injured, please provide details"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.injury_details}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel
                  id="needs-trap-select-outlined-label"
                  required={true}
                  error={errors.needs_trap && touched.needs_trap}
                >
                  Do the cat(s) need to be trapped?
                </InputLabel>
                <Select
                  native
                  name="needs_trap"
                  labelId="needs-trap-select-outlined-label"
                  id="needs-trap-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Do the cat(s) need to be trapped?"
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel id="needs-foster-select-outlined-label">
                  Do the cat(s) need to be fostered?
                </InputLabel>
                <Select
                  native
                  name="needs_foster"
                  labelId="needs-foster-select-outlined-label"
                  id="needs-foster-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Do the cat(s) need to be fostered?"
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <Typography>Please provide a detailed description of the cat(s) - gender (if known), color, markings, hair length.</Typography>
              <FormControl variant="outlined" className={classes.form}>
                <TextField
                  multiline={true}
                  name="cats_description"
                  id="cats-description-text-outlined"
                  label="Description of the cat(s)"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cats_description}
                  required={true}
                  error={errors.cats_description && touched.cats_description}
                  helperText={
                    errors.cats_description &&
                    touched.cats_description &&
                    errors.cats_description
                  }
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel id="number-of-kittens-select-outlined-label">
                  Number of kittens (between 0 and 6 months old)
                </InputLabel>
                <Select
                  native
                  name="number_of_kittens"
                  labelId="number-of-kittens-select-outlined-label"
                  id="number-of-kittens-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.number_of_kittens}
                  label="Number of kittens (between 0 and 6 months old)"
                  variant="outlined"
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel id="number-of-young-select-outlined-label">
                  Number of young cats (between 6 months and 3 years old)
                </InputLabel>
                <Select
                  native
                  name="number_of_young"
                  labelId="number-of-young-select-outlined-label"
                  id="number-of-young-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.number_of_young}
                  label="Number of young cats (between 6 months and 3 years old)"
                  variant="outlined"
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel id="number-of-adults-select-outlined-label">
                  Number of adult cats (between 3 years and 8 years old)
                </InputLabel>
                <Select
                  native
                  name="number_of_adults"
                  labelId="number-of-adults-select-outlined-label"
                  id="number-of-adults-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.number_of_adults}
                  label="Number of adult cats (between 3 years and 8 years old)"
                  variant="outlined"
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel id="number-of-senior-select-outlined-label">
                  Number of senior cats (over 8 years old)
                </InputLabel>
                <Select
                  native
                  name="number_of_seniors"
                  labelId="number-of-senior-select-outlined-label"
                  id="number-of-senior-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.number_of_seniors}
                  label="Number of senior cats (over 8 years old)"
                  variant="outlined"
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel
                  id="cats-inside-select-outlined-label"
                  required={true}
                  error={errors.cats_inside && touched.cats_inside}
                >
                  Are the cat(s) inside?
                </InputLabel>
                <Select
                  native
                  name="cats_inside"
                  labelId="cats-inside-select-outlined-label"
                  id="cats-inside-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Are the cat(s) inside?"
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel
                  id="can-put-in-carrier-select-outlined-label"
                  required={true}
                  error={
                    errors.can_put_in_carrier && touched.can_put_in_carrier
                  }
                >
                  Are you able to get the cat(s) into a pet carrier?
                </InputLabel>
                <Select
                  native
                  name="can_put_in_carrier"
                  labelId="can-put-in-carrier-select-outlined-label"
                  id="can-put-in-carrier-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Are you able to get the cat(s) into a pet carrier?"
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel id="needs-bottle-feed-select-outlined-label">
                  Do the cat(s) need to be bottle fed?
                </InputLabel>
                <Select
                  native
                  name="needs_bottle_feed"
                  labelId="needs-bottle-feed-select-outlined-label"
                  id="needs-bottle-feed-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Do the cat(s) need to be bottle fed?"
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel
                  id="litter-trained-select-outlined-label"
                  required={true}
                  error={errors.litter_trained && touched.litter_trained}
                >
                  Are the cat(s) litter trained?
                </InputLabel>
                <Select
                  native
                  name="litter_trained"
                  labelId="litter-trained-select-outlined-label"
                  id="litter-trained-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Are the cat(s) litter trained?"
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel
                  id="friendly-select-outlined-label"
                  required={true}
                  error={errors.friendly && touched.friendly}
                >
                  Are the cat(s) friendly?
                </InputLabel>
                <Select
                  native
                  name="friendly"
                  labelId="friendly-select-outlined-label"
                  id="friendly-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Are the cat(s) friendly?"
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
                          <Typography>If we are able to accept the cat(s) that you contact us about,
                  we would ask that you make a donation of at least $25 to help
                  cover the cost of caring for the cat(s) while in foster care
                  and getting needed vet work completed.</Typography>
              <FormControl className={classes.form} variant="outlined">
                <InputLabel id="donation-select-outlined-label">
                  Are you able to make a donation?
                </InputLabel>
                <Select
                  native
                  name="donation"
                  labelId="donation-select-outlined-label"
                  id="donation-select-outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="If we are able to accept the cat(s) that you contact us about, we would ask that you make a donation of at least $25 to help cover the cost of caring for the cat(s) while in foster care and getting needed vet work completed."
                >
                  <option value={null}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.row}>
                <CloudinaryButton
                  button={true}
                  photos={values.photos}
                  setPhotos={(p) => {setFieldValue("photos", p, true)}}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className={classes.form}>
              <FormControl variant="outlined" className={classes.form}>
                <Typography variant={"body1"} color={"error"}>
                  {error}
                </Typography>
                <Button
                  label="Submit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </FormControl>
            </FormGroup>
          </form>
        )}
      </Formik>
    </div>
  );
}
