import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { SearchContext } from "../App";
import Collection from "../components/Collection";
import Loading from "../components/Loading";
import PageTemplate from "../components/PageTemplate";
import { Error, Magnify } from "../components/catFails";
import { LabelsFilterDialog } from "../components/intakeLabels";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(4),
    marginLeft: "auto",
    marginRight: "auto",
  },
  noScroll: {
    overflow: "visible !important",
  },
  searchPadding: {
    padding: theme.spacing(0.5),
  },
}));

type IntakesPage = {
  results: Intake[],
  next: number | undefined,
  prev: number | undefined,
};
type Intake = {
  name: string,
};
type IntakesResponse = {
  results: Intake[],
  next: string,
  prev: string,
};
function assertIsIntakesResponse(response) {
  if (!("intakes" in response)) {
    throw new Error("Not results");
  }
  if (response.intakes.length > 0) {
    const firstResult = response.intakes[0];
    if (!("title" in firstResult)) {
      throw new Error("Not intakes");
    }
  }
}

export function SearchBar(props) {
  const classes = useStyles();
  const searchContext = React.useContext(SearchContext);
  const { searchText, setSearchText, filters, setFilters } = searchContext;
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [inputText, setInputText] = React.useState(searchText);
  const [filterText, setFilterText] = React.useState(filters);
  const [filterHelperText, setFilterHelperText] = React.useState(
    Object.entries(filterText)
      .filter(([k, v]) => v === true)
      .map((k, v) => k[0])
  );

  const handleSearchPress = (event) => {
    if (inputText && inputText.length > 0) {
      setError(false);
      setSearchText(inputText);
      setFilters(filterText);
      props.invalidateSearch();
    } else if (filterText) {
      setSearchText("");
      setError(false);
      setFilters(filterText);
      props.invalidateSearch();
    } else {
      setError(true);
    }
  };

  React.useEffect(() => {
    setFilters(filterText);
    props.invalidateSearch();
  }, [open]);

  React.useEffect(() => {
    setFilterHelperText(
      Object.entries(filterText)
        .filter(([k, v]) => v === true)
        .map((k, v) => k[0])
    );
  }, [filterText]);

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          fullWidth={true}
          error={error}
          variant="outlined"
          value={inputText}
          placeholder="Search title, name, email or county"
          inputProps={{ "aria-label": "search" }}
          helperText={`Filters: ${filterHelperText}`}
          onInput={(event) => {
            setInputText(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleSearchPress(event);
            }
          }}
          InputProps={
            inputText.length > 0
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setInputText("");
                        }}
                      >
                        <HighlightOffIcon color="disabled" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        ></TextField>
      </Grid>
      <Grid item className={classes.searchPadding}>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <TuneIcon fontSize="small" color="primary" />
        </IconButton>
        <LabelsFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filterText}
          setFilterText={setFilterText}
        />
      </Grid>
      <Grid item className={classes.searchPadding}>
        <IconButton onClick={handleSearchPress}>
          <SearchIcon color="primary" />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
}

export default function Intakes() {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const searchContext = React.useContext(SearchContext);
  const { searchText, setSearchText, filters, setFilters } = searchContext;
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    "intakes",
    async ({ pageParam = 0 }) => {
      const offset = parseInt(pageParam) || 0;
      const pageSize = 20;
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      const params = new URLSearchParams();
      params.append("offset", offset);
      params.append("page_size", pageSize);
      if (searchText !== "") {
        params.append("search", `email:${searchText}`);
        params.append("search", `submitted_by:${searchText}`);
        params.append("search", `title:${searchText}`);
        params.append("search", `county:${searchText}`);
      }
      Object.entries(filters).forEach((item) => {
        if (item[1] === true) {
          if (Array("new", "ready", "completed").includes(item[0])) {
            params.append("status", item[0]);
          } else {
            if (item[0] === "needs_trapper") {
              params.append("filter", "needs_trap");
            } else if (item[0] === 'injured') {
              params.append("filter", "injury_details");
            } else {
              params.append("filter", item[0]);
            }
          }
        }
      });
      const url = new URL(
        [
          `${process.env.REACT_APP_BACKEND_URL}/intakes`,
          params.toString(),
        ].join("?")
      );
      const response = await fetch(url, {
        headers: { Authorization: `bearer ${accessToken}` },
      });
      if (!response.ok) {
        throw new Error("Problem fetching data");
      }
      const dataFromServer = await response.json();
      assertIsIntakesResponse(dataFromServer);
      const data: IntakesPage = {
        results: dataFromServer.intakes,
        next:
          dataFromServer.next === null
            ? undefined
            : pageParam + pageSize,
      };
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.next,
    }
  );
  const fields = ["intake_date", "submitted_by", "address"];

  return (
    <PageTemplate>
      <Grid container spacing={1} className={classes.around}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={12} md={6} lg={6} className={classes.paddingTop}>
              <Typography variant="h3">Help Requests</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.paddingTop}>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justify="center"
                align="center"
              >
                <SearchBar
                  invalidateSearch={() =>
                    queryClient.invalidateQueries("intakes")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {status === "loading" && <Loading />}
          {!error && data && status !== "loading" && (
            <InfiniteScroll
              className={classes.noScroll}
              dataLength={data.pages.reduce((counter, page) => {
                return counter + page.results.length;
              }, 0)}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              endMessage={<Magnify error={"Looks like there's no more!"} />}
            >
              {data.pages.map((group, i) => (
                <div key={i}>
                  <Collection
                    items={group.results}
                    isLoaded={true}
                    link_type={"intakes"}
                    fields={fields}
                  />
                </div>
              ))}
            </InfiniteScroll>
          )}
          {status === "error" && <Error error={error.message} />}
        </Grid>
      </Grid>
    </PageTemplate>
  );
}
