import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import catBasket from "../assets/catBasket.svg";
import catReading from "../assets/catReading.svg";
import catEggs from "../assets/catEggs.svg";
import catTag from "../assets/catTag.svg";
import PageTemplate from "../components/PageTemplate";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  cover: {
    width: "100%",
    objectFit: "contain",
    padding: theme.spacing(2),
  },
  fixed: {
    maxWidth: 345,
    height: "fit-content",
    alignSelf: "center",
    margin: "auto",
  },
  padding: {
    padding: theme.spacing(3),
    backgroundColor: "white",
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
}));

function DashboardItem(props) {
  const classes = useStyles();

  const cardActionAreaProps = props.href.includes('http') ? { href: props.href} : { component: Link, to: props.href };

  return (
    <Card
      raised={true}
      className={clsx(classes.root, classes.fixed)}
      style={{ backgroundColor: props.color }}
    >
      <CardActionArea {...cardActionAreaProps}>
        <CardMedia
          component="img"
          height="300vh"
          image={props.image}
          title={props.title}
          className={classes.cover}
        />
        <CardContent className={classes.padding}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const Dashboard = () => {
  const classes = useStyles();
  const gridSizes = {
    xs: 12,
    md: 4,
    lg: 3,
  };

  return (
    <PageTemplate title="Welcome!">
      <Grid container spacing={1}>
        <Grid container className={classes.paddingTop} spacing={2}>
          <Grid item {...gridSizes}>
            <DashboardItem
              image={catBasket}
              title="Browse Help Requests"
              description={
                "Look at all help requests for cats. This includes house cats that have " +
                "been found outside, colonies that need TNR, or returns."
              }
              href="/intakes"
              color="#52d1a9"
            />
          </Grid>
          <Grid item {...gridSizes}>
            <DashboardItem
              image={catTag}
              title="Calendars"
              description={
                "View all the calendars."
              }
              href="/calendars"
              color="#cb52d1"
            />
          </Grid>
          <Grid item {...gridSizes}>
            <DashboardItem
              image={catEggs}
              title="Participating Vets"
              description={
                "Information and list of participating vets."
              }
              href="/vets"
              color="#94d152"
            />
          </Grid>
          <Grid item {...gridSizes}>
            <DashboardItem
              image={catReading}
              title="Announcements"
              description={
                "Here Ye, Here Ye! Here are the announcements you need, to keep up with the goings-on in our organization."
              }
              href="https://www.straycatblues.org/portal-announcements"
              color="#52d1cb"
            />
          </Grid>
        </Grid>
      </Grid>
    </PageTemplate>
  );
}

export default Dashboard;
