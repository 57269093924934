import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import SendIcon from "@material-ui/icons/Send";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
    fontWeight: "bold",
  },
  margins: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-1.5),
  },
  submitButton: {
    cursor: "pointer",
  },
}));

export default function EmailDialog(props) {
  const intakeId = props.intakeId;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(null);
  const [error, setError] = React.useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleClickOpen = () => {
    setError(null);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className={classes.margins}>
      <IconButton aria-label="email" onClick={handleClickOpen}>
        <EmailIcon />
      </IconButton>
      <Formik
        initialValues={
          initialValues || {
            teams: [],
            subject: "",
            body: "",
          }
        }
        validationSchema={Yup.object().shape({
          teams: Yup.array()
            .required("Required")
            .min(1)
            .ensure()
            .of(Yup.string().oneOf(["fosters", "trappers", "intakes"])),
          subject: Yup.string().required("Required").max(150).min(10),
          body: Yup.string().required("Required").max(500).min(30),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setError(null);
          try {
            const accessToken = await getAccessTokenSilently({
              audience: process.env.REACT_APP_BACKEND_AUDIENCE,
            });
            const teams = ["fosters", "intakes", "trappers"].filter(
              (elm) => values[elm]
            );
            const body = {
              body: values.body,
              subject: values.subject,
              teams: values.teams,
            };
            await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/intakes/${intakeId}/email`,
              {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            ).then(
              (response) => {
                if (!response.ok) {
                  setError(
                    `Failed to send: ${response.status} ${response.statusText}`
                  );
                } else {
                  resetForm();
                  setSubmitting(false);
                  setOpen(false);
                }
              },
              (error) => {
                setError(error.toString());
              }
            );
          } catch (e) {
            setError(e.message);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={(event) => handleSubmit}>
            <Dialog
              open={open}
              onClose={handleCancel}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Email</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Choose the following email groups to notify.
                </DialogContentText>
                <FormGroup>
                  <FormControl
                    required={true}
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormHelperText error={true}>{errors.teams}</FormHelperText>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="teams"
                          value="fosters"
                        />
                      }
                      label="fosters@straycatblues.org"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="teams"
                          value="trappers"
                        />
                      }
                      label="trappers@straycatblues.org"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="teams"
                          value="intakes"
                        />
                      }
                      label="intakes@straycatblues.org"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="teams"
                          value="help-requests"
                        />
                      }
                      label="helprequests@straycatblues.org"
                    />
                  </FormControl>
                </FormGroup>

                <FormGroup className={classes.form}>
                  <FormControl variant="outlined" className={classes.form}>
                    <TextField
                      margin="dense"
                      id="subject"
                      name="subject"
                      label="Subject"
                      variant="outlined"
                      required={true}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.subject && touched.subject}
                      helperText={
                        errors.subject && touched.subject && errors.subject
                      }
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup className={classes.form}>
                  <FormControl variant="outlined" className={classes.form}>
                    <TextField
                      multiline={true}
                      margin="dense"
                      id="body"
                      name="body"
                      label="Body"
                      variant="outlined"
                      required={true}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.body && touched.body}
                      helperText={errors.body && touched.body && errors.body}
                    />
                  </FormControl>
                </FormGroup>
                <FormHelperText error={true}>{error}</FormHelperText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    handleCancel();
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.submitButton}
                  onClick={handleSubmit}
                  color="primary"
                  disabled={isSubmitting}
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  );
}
