import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";

// styles
import "./App.css";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ProtectedRoute from "./components/protectedRoute";
import theme from "./theme";
import initFontAwesome from "./utils/initFontAwesome";
import Calendars from "./views/Calendars";
import Dashboard from "./views/Dashboard";
import IntakeView from "./views/Intake";
import Intakes from "./views/Intakes";
import IntakesNoInfinityScroll from "./views/IntakesNoInfinityScroll";
import Partners from "./views/Partners";
import Vets from "./views/Vets";
import addIntake from "./views/addIntake";

const queryClient = new QueryClient();
export const SearchContext = React.createContext(null);

initFontAwesome();

const App = () => {
  const { isLoading, error, logout } = useAuth0();
  const [filters, setFilters] = React.useState({
  new: false,
  ready: true,
  completed: false,
  needs_foster: false,
  needs_trapper: false,
  injured: false});
  const [searchText, setSearchText] = React.useState("");

  if (error) {
    return (
      <div>
        Oops... {error.message}
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Try Again
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SearchContext.Provider
        value = {{
          filters: filters,
          setFilters: setFilters,
          searchText: searchText,
          setSearchText: setSearchText,
        }}>
          <BrowserRouter>
            <Switch>
                <ProtectedRoute exact path="/" component={Dashboard} />
                <ProtectedRoute path="/partners" component={Partners} />
                <ProtectedRoute path="/calendars" component={Calendars} />
                <ProtectedRoute path="/vets" component={Vets} />
                
                {/* Intakes / Help Requests */}
                <ProtectedRoute exact path="/intakes" component={Intakes} />
                <ProtectedRoute exact path="/prior-2023-intakes" component={IntakesNoInfinityScroll} />
                <ProtectedRoute path="/intakes/:intakeId" component={IntakeView} />
                <Route path="/intake-form" component={addIntake} />
            </Switch>
          </BrowserRouter>
          <CssBaseline />
        </SearchContext.Provider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
