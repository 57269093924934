import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navigation from "../components/Navigation";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
  },
  title: {
    margin: theme.spacing(2),
  },
}));

export default function PageTemplate(props) {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        {/* Toolbar & Drawer/Menu */}
        <Navigation />     
        
        {/* Page Content */}
        <main id="scrollableCollection" className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            {/* Title */}
            {props.title && (
              <Typography variant="h3" className={classes.title}>
                {props.title}
              </Typography>
            )}

            {/* Body */}
            {props.children}
          </Container>
        </main>
      </div>
    );
}
