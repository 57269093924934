import React from "react";
import loading from "../assets/catLoading.svg";
import theme from "../theme";

const Loading = () => (
  <div
    className="spinner"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: theme.palette.background.default,
    }}
  >
    <img
      src={loading}
      alt="Loading"
    />
  </div>
);

export default Loading;
