import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  fixedHeight: {},
  root: {
    maxWidth: 440,
    height: "fit-content",
  },
  section: {
    margin: theme.spacing(1),
  },
  fullSize: {
    width: "100%",
    overflowWrap: "break-word",
    marginLeft: "auto",
    marginRight: "auto",
  },
  itemHeader: {
    color: theme.palette.primary.main,
  },
  container: {
    marginTop: "20px",
  },
  table: {
    minWidth: 650,
  },
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'image',
    headerName: 'Image',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
  },
    {
    field: 'title',
    headerName: 'Title',
    width: 150,
  },
  {
    field: 'comments',
    headerName: 'Comments',
    width: 200,
  },
    {
    field: 'edit',
    headerName: 'Edit',
    width: 90,
  },
];

// props
// isLoaded
// link_type
// fields -> keys for items map
// items -> { id, images, name, title, comments }

export default function HelpRequestsTable(props) {
  const classes = useStyles();

  // const rows = props.items.map((item) => {
  //   return { ...item };
  // });

  console.log(props.items);

  return (
    <div className={classes.container}>
      {props.isLoaded && props.items && (
        <div style={{ height: 400, width: '100%' }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {Object.keys(props.item[0]).map((key) => {
                    return (<TableCell align="center">{key}</TableCell>);
                  })}
                </TableRow>
              </TableHead>
              {/* <TableBody>
                {props.items.map((catRecord) => {
                  return (
                    <TableRow>
                      {Object.entries(catRecord).map(([_, value]) => {
                        return (
                          <TableCell align="right" scope="row">{value}</TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody> */}
            </Table>
          </TableContainer>
          
        </div>
      )}
    </div>
  );
}
