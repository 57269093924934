import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { DeleteButton } from "../components/Editing";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "pre-line",
    overflowWrap: "break-word",
  },
  margins: {
    "& > *": {
      margin: theme.spacing(3),
    },
  },
  commentButton: {
    backgroundColor: "#52d1a9",
    color: "black !important",
    borderColor: "#52d1a9",
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  update: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  updateItem1: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  updateItem2: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  updatePaper: {
    backgroundColor: theme.palette.background.default,
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  around: {
    padding: theme.spacing(1),
  },
  leftPadding: {
    paddingLeft: theme.spacing(2),
  },
  bottomPadding: {
    paddingBottom: theme.spacing(1),
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export function DeleteCommentDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    props.deleteComment(props.deleteLink);
    setOpen(false);
  };

  return (
    <div>
      <DeleteButton onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            endIcon={<CheckCircleIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function Comments(props) {
  const classes = useStyles();
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [error, setError] = React.useState(null);
  async function deleteComment(deleteLink) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      await fetch(deleteLink, {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }).then(
        (result) => {
          props.setComments(
            props.comments.filter(
              (comment) => comment.delete_link !== deleteLink
            )
          );
          props.setIsLoaded(true);
        },
        (error) => {
          props.setIsLoaded(true);
          props.setError(error.toString());
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }
  async function postComment(values) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/updates?intake_id=${props.intakeId}`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ comment: values.comment }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            props.setComments(result.comments.reverse());
            props.setIsLoaded(true);
            values.comment = "";
          },
          (error) => {
            props.setIsLoaded(true);
            props.setError(error.toString());
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        {props.comments.map((item, index) => (
          <Grid
            key={index}
            container
            className={clsx({
              [classes.bottomPadding]: props.short,
              [classes.leftPadding]: !props.short,
              [classes.updateItem1]: !props.short,
            })}
          >
            {props.removeAvatar || (
              <Grid item xs={3} md={2} lg={1}>
                {(item.created_by === "noreply@straycatblues.org" && (
                  <Avatar className={classes.large}>
                    <NotificationsIcon />
                  </Avatar>
                )) || (
                  <Avatar className={classes.large}>
                    {item.created_by[0].toUpperCase()}
                  </Avatar>
                )}
              </Grid>
            )}
            <Grid
              item
              xs={props.short ? 12 : 9}
              md={props.short ? 12 : 10}
              lg={props.short ? 12 : 11}
            >
              <Grid container alignItems="baseline" spacing={1}>
                <Grid item>
                  <Box variant="subtitle2" fontWeight="fontWeightBold">
                    {item.created_by === "noreply@straycatblues.org"
                      ? "Notification"
                      : item.created_by.split("@")[0]}
                  </Box>
                </Grid>
                <Grid item>
                  <Typography variant={"caption"}>
                    {new Date(item.created_at).toLocaleString()}
                  </Typography>
                </Grid>
                {!props.short && user.sub.split("|")[1] === item.created_by && (
                  <Grid item>
                    <DeleteCommentDialog
                      deleteComment={deleteComment}
                      deleteLink={item.delete_link}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={3} md={2} lg={1}></Grid>
            <Grid
              item
              xs={props.short ? 12 : 9}
              md={props.short ? 12 : 10}
              lg={props.short ? 12 : 11}
            >
              <Typography variant="body1" className={classes.root}>
                {props.short && item.text.slice(0, 100)}
                {props.short && item.text.length > 100 && "..."}
                {!props.short && item.text}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {props.removeNewComment || (
        <Formik
          initialValues={{ comment: "" }}
          validationSchema={Yup.object().shape({
            comment: Yup.string().required("Required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await postComment(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={(event) => true} className={classes.fullWidth}>
              <Grid item xs={12} md={12} lg={12} className={classes.update}>
                <Grid container className={classes.updateItem1}>
                  <Grid item xs={3} md={2} lg={1}>
                    <Avatar className={classes.large}></Avatar>
                  </Grid>
                  <Grid item xs={9} md={10} lg={11}>
                    <FormGroup className={classes.form}>
                      <FormControl
                        variant="outlined"
                        className={clsx(classes.form)}
                      >
                        <TextField
                          multiline={true}
                          name="comment"
                          id="comment-text-outlined"
                          label="New Comment"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.comment}
                          error={errors.comment && touched.comment}
                          helperText={
                            errors.comment && touched.comment && errors.comment
                          }
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container justify={"flex-end"}>
                  <Grid item>
                    {error}
                    <Button
                      label="Submit"
                      type="submit"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      size="large"
                      aria-label="add-comment"
                      className={clsx(classes.button, classes.commentButton)}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </Grid>
  );
}
