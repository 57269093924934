import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles, styled } from "@material-ui/core/styles";
import React from "react";
import catReading from "../assets/catReading.svg";
import PageTemplate from "../components/PageTemplate";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: "40px",
  }
}));

const LINKS = {
  "ACCT Philly": {
    url: "http://www.acctphilly.org/lovelocalcats",
    img: "https://www.acctphilly.org/wp-content/uploads/2014/08/ACCT-Philly-cat-site-logo.png"
  },
  "The Cat Collaborative": {
    url: "https://www.thecatcollaborative.org/",
    img: "https://www.thecatcollaborative.org/uploads/1/4/3/6/143631862/published/logo-edited.png?1669911931"
  },
  "Active and Available Cats": {
    url: "https://airtable.com/shrxnwh626ecm72Kr",
    img: catReading,
  },
  "TCC Cat Request Form": {
    url: "https://airtable.com/shrBEPFuGkAeFce5g",
    img: catReading,
  },
  "Montgomery County SPCA": {
    url: "https://montgomerycountyspca.org/",
    img: "https://montgomerycountyspca.org/wp-content/uploads/logo-500px.png",
  },
};

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '40%',
  maxHeight: '100%',
});

export default function Partners() {
  const classes = useStyles();

  return (
    <PageTemplate title="Partners">
      {/* Links */}
      <Grid container rowSpacing={2}>
        {Object.entries(LINKS).map(([name, { url, img }], index) => (
          <Grid key={index} container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} className={classes.marginBottom}>
            <Grid item xs={3}>
              <Img alt="" src={img} />
            </Grid>
            <Grid item>
              <Link
                href={url}
                target="_blank" rel="noopener" // Open in new tab
              >
                <Typography variant="button" display="block" gutterBottom>
                  {name}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </PageTemplate>
  );
}
