import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Navigation from "../components/Navigation";
import Collection from "../components/Collection";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useAuth0 } from "@auth0/auth0-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../components/Loading";
import { Magnify, Error } from "../components/catFails";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TuneIcon from "@material-ui/icons/Tune";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import { useInfiniteQuery, useQueryClient } from "react-query";
import MenuItem from "@material-ui/core/MenuItem";
import { Label, LabelsFilterDialog } from "../components/intakeLabels";
import { SearchContext } from "../App";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Formik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import HelpRequestsTable from "../components/HelpRequestsTable";
import { Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  alignRight: {
    paddingTop: theme.spacing(2),
    justifyContent: "flex-end",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
    marginLeft: "auto",
    marginRight: "auto",
  },
  shadow: {
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(1),
    width: "100%",
  },
  noScroll: {
    overflow: "visible !important",
  },
  searchPadding: {
    padding: theme.spacing(0.5),
  },
}));

type IntakesPage = {
  results: Intake[],
  next: number | undefined,
  prev: number | undefined,
};
type Intake = {
  name: string,
};
type IntakesResponse = {
  results: Intake[],
  next: string,
  prev: string,
};
function assertIsIntakesResponse(response) {
  if (!("intakes" in response)) {
    throw new Error("Not results");
  }
  if (response.intakes.length > 0) {
    const firstResult = response.intakes[0];
    if (!("title" in firstResult)) {
      throw new Error("Not intakes");
    }
  }
}

export function SearchBar(props) {
  const classes = useStyles();
  const searchContext = React.useContext(SearchContext);
  const { searchText, setSearchText, filters, setFilters } = searchContext;
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [inputText, setInputText] = React.useState(searchText);
  const [filterText, setFilterText] = React.useState(filters);
  const [filterHelperText, setFilterHelperText] = React.useState(
    Object.entries(filterText)
      .filter(([k, v]) => v === true)
      .map((k, v) => k[0])
  );

  const handleSearchPress = (event) => {
    if (inputText && inputText.length > 0) {
      setError(false);
      setSearchText(inputText);
      setFilters(filterText);
      props.invalidateSearch();
    } else if (filterText) {
      setSearchText("");
      setError(false);
      setFilters(filterText);
      props.invalidateSearch();
    } else {
      setError(true);
    }
  };

  React.useEffect(() => {
    setFilters(filterText);
    props.invalidateSearch();
  }, [open]);

  React.useEffect(() => {
    setFilterHelperText(
      Object.entries(filterText)
        .filter(([k, v]) => v === true)
        .map((k, v) => k[0])
    );
  }, [filterText]);

  return (
    <React.Fragment>
      <Grid item xs={10} md={10} lg={10}>
        <TextField
          fullWidth={true}
          error={error}
          variant="outlined"
          value={inputText}
          placeholder="Search title, name, email or county"
          inputProps={{ "aria-label": "search" }}
          helperText={`Filters: ${filterHelperText}`}
          onInput={(event) => {
            setInputText(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleSearchPress(event);
            }
          }}
          InputProps={
            inputText.length > 0
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setInputText("");
                        }}
                      >
                        <HighlightOffIcon color="disabled" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        ></TextField>
      </Grid>
      <Grid item className={classes.searchPadding}>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <TuneIcon fontSize="small" color="primary" />
        </IconButton>
        <LabelsFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filterText}
          setFilterText={setFilterText}
        />
      </Grid>
      <Grid item className={classes.searchPadding}>
        <IconButton onClick={handleSearchPress}>
          <SearchIcon color="primary" />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
}

export default function IntakesNoInfinityScroll() {
  //const [isTableView, setIsTableView] = useState(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState();

  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const searchContext = React.useContext(SearchContext);
  const { searchText, setSearchText, filters, setFilters } = searchContext;
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    "intakes",
    async ({ pageParam = 0 }) => {
      const offset = parseInt(pageParam) || 0;
      const pageSize = 100000;
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BACKEND_AUDIENCE,
      });
      const params = new URLSearchParams();
      params.append("offset", offset);
      params.append("page_size", pageSize);
      if (searchText !== "") {
        params.append("search", `email:${searchText}`);
        params.append("search", `submitted_by:${searchText}`);
        params.append("search", `title:${searchText}`);
        params.append("search", `county:${searchText}`);
      }
      Object.entries(filters).forEach((item) => {
        if (item[1] === true) {
          if (Array("new", "ready", "completed").includes(item[0])) {
            params.append("status", item[0]);
          } else {
            if (item[0] === "needs_trapper") {
              params.append("filter", "needs_trap");
            } else if (item[0] === 'injured') {
              params.append("filter", "injury_details");
            } else {
              params.append("filter", item[0]);
            }
          }
        }
      });
      const url = new URL(
        [
          `${process.env.REACT_APP_BACKEND_URL}/intakes`,
          params.toString(),
        ].join("?")
      );
      const response = await fetch(url, {
        headers: { Authorization: `bearer ${accessToken}` },
      });
      if (!response.ok) {
        throw new Error("Problem fetching data");
      }
      const dataFromServer = await response.json();
      assertIsIntakesResponse(dataFromServer);
      const data: IntakesPage = {
        results: dataFromServer.intakes,
        next:
          dataFromServer.next === null
            ? undefined
            : pageParam + pageSize,
      };
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.next,
    }
  );
  const fields = ["intake_date", "submitted_by", "address"];

  useEffect(() => {
    if (true/*isTableView*/) {
      /* Set loading state */
      setIsLoadingTableData(true);

      /* Load new data and update page state */
      const getTabularData = async (page) => {
        // URL construction
        const params = new URLSearchParams();
        // Page Params
        const offset = parseInt(page) || 0;
        const pageSize = 1000000; // hack to remove pagination reqs in the backend
        params.append("offset", offset);
        params.append("page_size", pageSize);
        // Search term params
        if (searchText !== "") {
          params.append("search", `email:${searchText}`);
          params.append("search", `submitted_by:${searchText}`);
          params.append("search", `title:${searchText}`);
          params.append("search", `county:${searchText}`);
        }
        // Status filter params
        Object.entries(filters).forEach((item) => {
          if (item[1] === true) {
            if (Array("new", "ready", "completed").includes(item[0])) {
              params.append("status", item[0]);
            } else {
              if (item[0] === "needs_trapper") {
                params.append("filter", "needs_trap");
              } else if (item[0] === 'injured') {
                params.append("filter", "injury_details");
              } else {
                params.append("filter", item[0]);
              }
            }
          }
        });
        // Build URL with params
        const url = new URL(
          [
            `${process.env.REACT_APP_BACKEND_URL}/intakes`,
            params.toString(),
          ].join("?")
        );

        // Fetch data
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_BACKEND_AUDIENCE,
        });
        const response = await fetch(url, {
          headers: { Authorization: `bearer ${accessToken}` },
        });

        // Handle response
        if (!response.ok) {
          throw new Error("Problem fetching data");
        }
        const dataFromServer = await response.json();
        assertIsIntakesResponse(dataFromServer);

        // Update page state
        setTableData(dataFromServer.intakes);
        setIsLoadingTableData(false);
      };

      /* Trigger data fetch */
      getTabularData();
    }
  }, [/*isTableView,*/ searchText, filters, getAccessTokenSilently]);

  return (
    <div className={classes.root}>
      <Navigation />
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          maxWidth="lg"
          className={classes.container}
          id="scrollableCollection"
        >
          <Grid container spacing={1} className={classes.around}>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container direction="row" justify="space-between">
                <Grid item xs={12} md={6} lg={6} className={classes.paddingTop}>
                  <Typography variant="h3">Help Requests Prior to 1/1/2023</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6} className={classes.paddingTop}>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justify="center"
                    align="center"
                  >
                    <SearchBar
                      invalidateSearch={() =>
                        queryClient.invalidateQueries("intakes")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {/*status === "loading"*/ isLoadingTableData && <Loading />}
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={isTableView}
                    onChange={(event) => setIsTableView(event.target.checked)}
                    color="primary"
                  />
                }
                label="Show Table View"
              /> */}
              {/* {isTableView && (
                <HelpRequestsTable
                  items={tableData}
                  isLoaded={!isLoadingTableData}
                  link_type={"intakes"}
                  fields={fields}
                />
              )} */}
              {/*isTableView &&*/ !error && tableData && /*status !== "loading"*/ !isLoadingTableData && (
                // <InfiniteScroll
                //   className={classes.noScroll}
                //   dataLength={tableData.pages.reduce((counter, page) => {
                //     return counter + page.results.length;
                //   }, 0)}
                //   next={fetchNextPage}
                //   hasMore={!!hasNextPage}
                //   endMessage={<Magnify error={"Looks like there's no more!"} />}
                // >
                //   {tableData.pages.map((group, i) => (
                //     <div key={i}>
                //       <Collection
                //         items={group.results}
                //         isLoaded={true}
                //         link_type={"intakes"}
                //         fields={fields}
                //       />
                //     </div>
                //   ))}
                // </InfiniteScroll>
                  <Collection
                    items={tableData.filter((intake) => !intake['intake_date'].includes("2023"))}
                    isLoaded={true}
                    link_type={"intakes"}
                    fields={fields}
                    newTab
                  />
              )}
              {status === "error" && <Error error={error.message} />}
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
