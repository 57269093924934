import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles((theme) => ({
  margins: {
    margin: theme.spacing(1),
  },
  success: {
    color: "#52d1a9",
  },
  warn: {
    color: "#f44336",
  },
}));
export function EditButton (props) {
  const classes = useStyles();
return (
    <Grid item className={classes.margins}>
        <IconButton id={props.editButtonId} onClick={props.onClick}>
          <EditIcon fontSize="small" color="disabled" />
        </IconButton>
    </Grid>
);
}

export function DeleteButton (props) {
  const classes = useStyles();
return (
    <Grid item className={classes.margins}>
        <IconButton onClick={props.onClick}>
          <DeleteIcon fontSize="small" color="disabled" />
        </IconButton>
    </Grid>
);
}

export function AddButton (props) {
  const classes = useStyles();
return (
    <Grid item className={classes.margins}>
        <IconButton onClick={props.onClick}>
          <AddIcon fontSize="small" />
        </IconButton>
    </Grid>
);
}

export function RemoveButton (props) {
  const classes = useStyles();
return (
    <Grid item className={classes.margins}>
        <IconButton onClick={props.onClick}>
          <RemoveCircleOutlineIcon fontSize="small" />
        </IconButton>
    </Grid>
);
}