import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Image from "material-ui-image";
import React from "react";

class CloudinaryButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: props.photos,
      widget: window.cloudinary.createUploadWidget(
        {
          cloudName: "stray-cat-blues",
          uploadPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
          sources: ["local", "camera"],
          theme: "minimal", 
          clientAllowedFormats: ["jpg", "jpeg", "png"], //restrict uploading to image files only
          // maxImageFileSize: 4000000,  //restrict file size to less than 4MB - This SHOULD be restricted but no one knows how to resize an image despite it being super easy so here we are...        
          buttonCaption: "Add photo",
          form: "#image-form",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            this.props.setPhotos([
              ...this.state.photos,
              result.info.secure_url,
            ]);
          }
        }
      ),
    };
  }

  showWidget = () => {
    this.state.widget.open();
  };
  hideWidget = () => {
    this.state.widget.close();
  };
  render() {
    return (
      <Grid item style={this.props.style}>
        {this.props.button && (
          <div id="image-form">
            <Button
              label="Add images"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.showWidget()}
            >
              + Add images
            </Button>
            {this.state.photos.map((link, index) => (
              <Image src={link} key={index} />
            ))}
            <input type="hidden" name="image" value={this.state.photos} />
          </div>
        )}
        {this.props.icon && (
          <IconButton onClick={() => this.showWidget()}>
            <AddIcon style={this.props.iconStyle} />
          </IconButton>
        )}
      </Grid>
    );
  }
}

export default CloudinaryButton;
