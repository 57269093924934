import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import history from "./utils/history";
import HttpsRedirect from 'react-https-redirect';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

ReactDOM.render(
<HttpsRedirect>
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    audience={process.env.REACT_APP_BACKEND_AUDIENCE}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    scope='read:current_user write:cat write:intake read'
    advancedOptions={{defaultScope: 'read:current_user'}}
  >
    <App />,
  </Auth0Provider>
        </HttpsRedirect>,
  document.getElementById("root")
);
