import { RadioGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import { Formik } from "formik";
import React from "react";
import { EditButton } from "../components/Editing";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  newButton: {
    backgroundColor: "#52d1a9",
    color: "black !important",
    fontWeight: "bold",
  },
  completedButton: {
    backgroundColor: "#2196f3",
    color: "black !important",
    fontWeight: "bold",
  },
  trapButton: {
    backgroundColor: "#d152ba",
    color: "black !important",
    fontWeight: "bold",
  },
  fosterButton: {
    backgroundColor: "#ffb74d",
    color: "black !important",
    fontWeight: "bold",
  },
  injuredButton: {
    backgroundColor: "#f44336",
    color: "black !important",
    fontWeight: "bold",
  },
  readyButton: {
    backgroundColor: "#76ff03",
    color: "black !important",
    fontWeight: "bold",
  },
  button: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  labelSpace: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

export function determineLabels(data) {
  const labels = [];
  if (data.injury_details && data.injury_details.length > 0) {
    labels.push("injured");
  }
  if (data.needs_foster === true) {
    labels.push("needs_foster");
  }
  if (data.needs_trap === true) {
    labels.push("needs_trapper");
  }
  if (data.status === "new") {
    labels.push("new");
  } else if (data.status === "ready") {
    labels.push("ready");
  } else if (data.status === "completed") {
    labels.push("completed");
  }
  return labels;
}

function getStatus(data = []) {
  if (data.includes("new")) {
    return "new";
  } else if (data.includes("ready")) {
    return "ready";
  } else if (data.includes("completed")) {
    return "completed";
  }
}

export function Label(props) {
  const classes = useStyles();

  return (
    <Button
      key={props.label}
      variant="outlined"
      size="small"
      aria-label={props.label}
      className={clsx(classes.button, {
        [classes.fosterButton]:
          props.label === "needs_foster" && !props.disabled,
        [classes.injuredButton]: props.label === "injured" && !props.disabled,
        [classes.trapButton]:
          props.label === "needs_trapper" && !props.disabled,
        [classes.newButton]: props.label.includes("new") && !props.disabled,
        [classes.readyButton]: props.label.includes("ready") && !props.disabled,
        [classes.completedButton]:
          props.label.includes("completed") && !props.disabled,
      })}
      disabled={props.handleClickEdit ? false : true}
      onClick={props.handleClickEdit}
    >
      {props.label.toUpperCase()}
    </Button>
  );
}

export function Labels(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickEdit = () => {
    setOpen(true);
  };
  return (
    <Grid container justify={"flex-start"}>
      {!props.setLabels && (
        <Grid item xs={10}>
          <LabelsView labels={props.labels} />
        </Grid>
      )}
      {props.setLabels && (
        <Grid item xs={10}>
          <LabelsView labels={props.labels} handleClickEdit={handleClickEdit} />
        </Grid>
      )}
      {props.setLabels && (
        <div>
          <Grid item xs={2}>
            <EditButton editButtonId={props.editButtonId} onClick={handleClickEdit} />
          </Grid>
          <LabelsDialogEditor
            labels={props.labels}
            setLabels={props.setLabels}
            open={open}
            setOpen={setOpen}
            updateLabels={props.updateLabels}
          />
        </div>
      )}
    </Grid>
  );
}

export function LabelsView(props) {
  const classes = useStyles();

  return (
    <Grid item>
      <Grid container alignItems={"baseline"}>
        <Grid item>
          {props.labels.map((label) => (
            <Label
              label={label}
              key={"label-" + label}
              handleClickEdit={props.handleClickEdit}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export function LabelsDialogEditor(props) {
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState(null);

  const handleCancel = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Formik
        initialValues={
          initialValues || {
            status: getStatus(props.labels),
            injured: props.labels.includes("injured"),
            needs_trapper: props.labels.includes("needs_trapper"),
            needs_foster: props.labels.includes("needs_foster"),
          }
        }
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          props.updateLabels(values);
          setSubmitting(false);
          props.setOpen(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={(event) => handleSubmit}>
            <Dialog
              open={props.open}
              onClose={handleCancel}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Manage labels</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Change the labels for a help request. Changing the labels will
                  notify everyone in the People section of the help request.
                </DialogContentText>
                <FormGroup className={classes.form}>
                  <FormControl variant="outlined" className={classes.form}>
                    <RadioGroup
                      row
                      aria-label="status"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        id="radio-status-new"
                        value="new"
                        checked={values.status === "new"}
                        control={<Radio />}
                        label={
                          <Label
                            label={"new"}
                            disabled={values.status !== "new"}
                          />
                        }
                      />
                      <FormControlLabel
                        id="radio-status-ready"
                        value="ready"
                        checked={values.status === "ready"}
                        control={<Radio />}
                        label={
                          <Label
                            label={"ready"}
                            disabled={values.status !== "ready"}
                          />
                        }
                      />
                      <FormControlLabel
                        id="radio-status-completed"
                        value="completed"
                        checked={values.status === "completed"}
                        control={<Radio />}
                        label={
                          <Label
                            label={"completed"}
                            disabled={values.status !== "completed"}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-needs-foster"
                        checked={values.needs_foster}
                        onChange={handleChange}
                        value={true}
                        name="needs_foster"
                        color="primary"
                      />
                    }
                    label={
                      <Label
                        label={"needs_foster"}
                        disabled={!values.needs_foster}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-needs-trapper"
                        checked={values.needs_trapper}
                        onChange={handleChange}
                        value={true}
                        name="needs_trapper"
                        color="primary"
                      />
                    }
                    label={
                      <Label
                        label={"needs_trapper"}
                        disabled={!values.needs_trapper}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup row className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-injured"
                        checked={values.injured}
                        onChange={handleChange}
                        value={true}
                        name="injured"
                        color="primary"
                        disabled
                      />
                    }
                    label={
                      <Label label={"injured"} disabled={!values.injured} />
                    }
                  />
                  <FormHelperText className={classes.labelSpace}>
                    Coming soon!
                  </FormHelperText>
                </FormGroup>
                <DialogContentText className={classes.labelSpace}>
                  When the help request status is changed to READY, an email will be
                  sent automatically to notify all fosters and trappers
                  that the help request is ready
                  (if NEEDS_FOSTER or NEEDS_TRAPPER are checked at time of status change).
                  You can know if trappers or fosters were
                  notified by checking the comments.
                </DialogContentText>
                {error}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    handleCancel();
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  endIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  );
}

export function LabelsFilterDialog(props) {
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState(null);

  const handleCancel = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues || props.filters}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          props.setFilterText(values);
          handleCancel();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={(event) => handleSubmit}>
            <Dialog
              open={props.open}
              onClose={props.handleCancel}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Apply filters</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Choose filters to apply to your search. If checked, the search
                  will include help requests that match all of the selected filter
                  criterion.
                </DialogContentText>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-status-new"
                        checked={values.new}
                        onChange={handleChange}
                        value={true}
                        name="new"
                        color="primary"
                      />
                    }
                    label={<Label label={"new"} disabled={!values.new} />}
                  />
                </FormGroup>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-status-ready"
                        checked={values.ready}
                        onChange={handleChange}
                        name="ready"
                        value={true}
                        color="primary"
                      />
                    }
                    label={<Label label={"ready"} disabled={!values.ready} />}
                  />
                </FormGroup>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-status-completed"
                        onChange={handleChange}
                        checked={values.completed}
                        name="completed"
                        value={true}
                        color="primary"
                      />
                    }
                    label={
                      <Label label={"completed"} disabled={!values.completed} />
                    }
                  />
                </FormGroup>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-needs-foster"
                        checked={values.needs_foster}
                        onChange={handleChange}
                        value={true}
                        name="needs_foster"
                        color="primary"
                      />
                    }
                    label={
                      <Label
                        label={"needs_foster"}
                        disabled={!values.needs_foster}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-needs-trapper"
                        checked={values.needs_trapper}
                        onChange={handleChange}
                        value={true}
                        name="needs_trapper"
                        color="primary"
                      />
                    }
                    label={
                      <Label
                        label={"needs_trapper"}
                        disabled={!values.needs_trapper}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup row className={classes.form}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkbox-injured"
                        checked={values.injured}
                        onChange={handleChange}
                        value={true}
                        name="injured"
                        color="primary"
                      />
                    }
                    label={
                      <Label label={"injured"} disabled={!values.injured} />
                    }
                  />
                </FormGroup>
                {error}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    handleCancel();
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  endIcon={<CheckIcon />}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Done
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  );
}
