import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import { EditButton } from "../components/Editing";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  fosterButton: {
    backgroundColor: "#ffb74d",
    color: "black !important",
    fontWeight: "bold",
  },
  subscribeButton: {
    backgroundColor: "#2196f3",
    color: "black !important",
    fontWeight: "bold",
  },
  unsubscribeButton: {
    color: "black !important",
    borderColor: "#2196f3",
    fontWeight: "bold",
  },
  createCatButton: {
    color: "black !important",
    borderColor: "#52d1a9",
    fontWeight: "bold",
  },
  notification: {
    width: "100%",
  },
  itemHeader: {
    color: theme.palette.primary.main,
  },
  fit: {
    paddingLeft: theme.spacing(2),
  },
  headerSize: {
    fontSize: "2.125rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    lineHeight: "1.235",
    letterSpacing: "0.00735em",
  },
  fullWidth: {
    width: "100%",
  },
  margins: {
    margin: theme.spacing(1),
  },
}));

function AssignmentsDialog(props) {
  return (
    <div>
      {props.assignments && props.assignmentType && (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Manage {props.assignmentType}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add/Remove yourself from the {props.assignmentType} of a help request.
              Changing the {props.assignmentType} will notify the other People on the help request.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            {!props.assignments[props.assignmentType].includes(
              props.username.split("|")[1]
            ) && (
              <Button
                onClick={() => props.handleAddAssignment(props.assignmentType)}
                color="primary"
                endIcon={<CheckCircleIcon />}
              >
                Assign me
              </Button>
            )}
            {props.assignments[props.assignmentType].includes(
              props.username.split("|")[1]
            ) && (
              <Button
                onClick={() =>
                  props.handleRemoveAssignment(props.assignmentType)
                }
                color="primary"
                endIcon={<HighlightOffIcon />}
              >
                Remove me
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export function Assignments(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [assignmentType, setAssignmentType] = React.useState(null);

  const handleClickOpen = (assignmentType) => {
    setAssignmentType(assignmentType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddAssignment = (assignmentType) => {
    props.addAssignment(assignmentType);
    setOpen(false);
  };

  const handleRemoveAssignment = (assignmentType) => {
    props.removeAssignment(assignmentType);
    setOpen(false);
  };

  return (
    <div>
      {Object.entries(props.assignments).map(([k, v]) => (
        <Grid
          container
          className={classes.fit}
          key={k + "-container"}
          direction="row"
          justify="flex-start"
          spacing={1}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item>
                <Typography
                  className={classes.itemHeader}
                  gutterBottom
                  variant="h5"
                >
                  {k}
                </Typography>
              </Grid>
              <Grid item className={classes.margins}>
                {props.username &&
                  v &&
                  v.includes(props.username.split("|")[1]) && (
                    <IconButton onClick={() => handleClickOpen(k)}>
                      <RemoveCircleIcon fontSize="small" color="disabled" />
                    </IconButton>
                  )}
                {props.username &&
                  v &&
                  !v.includes(props.username.split("|")[1]) && (
                    <IconButton onClick={() => handleClickOpen(k)}>
                      <GroupAddIcon fontSize="small" color="disabled" />
                    </IconButton>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {props.assignments && (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="baseline"
                spacing={3}
              >
                {v &&
                  v.length > 0 &&
                  v.map((email) => (
                    <Grid item key={email + "-emails"}>
                      <Grid
                        key={email + "email"}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="baseline"
                        spacing={2}
                        className={classes.fullWidth}
                      >
                        <Grid item xs={4}>
                          <Avatar className={classes.large}>
                            {email.split("@")[0][0].toUpperCase()}
                          </Avatar>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            {email.split("@")[0]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      <AssignmentsDialog
        open={open}
        assignmentType={assignmentType}
        username={props.username}
        assignments={props.assignments}
        handleClose={handleClose}
        handleAddAssignment={handleAddAssignment}
        handleRemoveAssignment={handleRemoveAssignment}
      />
    </div>
  );
}

export function SubscribeDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const subText = props.isSubscribed ? "Unfollow" : "Follow";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleSubscribe = () => {
    props.handleSubscription();
    setOpen(false);
  };

  return (
    <div>
      <Grid
        container
        className={classes.fit}
        direction="row"
        justify="flex-start"
        spacing={1}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
          >
            <Grid item>
              <Typography
                className={classes.itemHeader}
                gutterBottom
                variant="h5"
              >
                followers
              </Typography>
            </Grid>
            <Grid item className={classes.margins}>
              {props.isSubscribed && (
                <IconButton onClick={handleClickOpen}>
                  <RemoveCircleIcon fontSize="small" color="disabled" />
                </IconButton>
              )}
              {!props.isSubscribed && (
                <IconButton onClick={handleClickOpen}>
                  <GroupAddIcon fontSize="small" color="disabled" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="baseline"
            spacing={3}
          >
            {props.followers &&
              props.followers.length > 0 &&
              props.followers.map((email) => (
                <Grid item key={email + "-emails"}>
                  <Grid
                    key={email + "email"}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="baseline"
                    spacing={2}
                    className={classes.fullWidth}
                  >
                    <Grid item xs={4}>
                      <Avatar className={classes.large}>
                        {email.split("@")[0][0].toUpperCase()}
                      </Avatar>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {email.split("@")[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{subText}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {subText} to all updates for this help request. This includes emails for
            the following events:
          </DialogContentText>

          <List
            component="nav"
            className={classes.notification}
            aria-label="contacts"
          >
            <ListItem button>
              <ListItemIcon>
                <NotificationsNoneIcon />
              </ListItemIcon>
              <ListItemText primary="Help Request Settings Changes" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <NotificationsNoneIcon />
              </ListItemIcon>
              <ListItemText primary="Help Request Status Changes" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubscribe}
            color="primary"
            endIcon={<CheckCircleIcon />}
          >
            {subText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function CreateCatDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleCreateCat = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="large"
        aria-label="create cat"
        className={clsx(classes.button, classes.createCatButton)}
        onClick={handleClickOpen}
      >
        Create Tracker ID
      </Button>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create a cat</DialogTitle>
        <DialogContent>
          <DialogContentText>
            COMING SOON! Create a tracker ID by adding a cat to a foster home,
            or adding a TNR cat.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCreateCat}
            color="primary"
            endIcon={<CheckCircleIcon />}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TitleEditorDialog(props) {
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState({
    title: props.title,
  });

  return (
    <div>
      <Formik
        initialValues={{ title: props.title }}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          props.updateTitle(values);
          setSubmitting(false);
          props.setOpen(false);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={(event) => handleSubmit}>
            <Dialog
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Edit title</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Changing the title will notify followers of the change.
                </DialogContentText>
                <FormGroup className={classes.form}>
                  <FormControl variant="outlined" className={classes.form}>
                    <TextField
                      autoFocus={true}
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="true"
                      multiline
                      placeholder="Add title"
                      aria-describedby="standard-title-helper-text"
                    />
                    {errors.title && touched.title && errors.title}
                  </FormControl>
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    props.handleClose();
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  endIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  );
}

const StyledInput = styled(Input)`
  .Mui-disabled {
    color: black;
  }
`;

export function TitleEditor(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeTitle = () => {
    setOpen(false);
  };
  return (
    <div className={classes.fullWidth}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={10}>
          <Typography className={classes.headerSize} onClick={handleClickOpen}>
            {props.title || "Add title"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <EditButton onClick={handleClickOpen} />
        </Grid>
      </Grid>
      <TitleEditorDialog
        title={props.title}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        updateTitle={props.updateTitle}
      />
    </div>
  );
}
