import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Loading from "../components/Loading";
import PageTemplate from "../components/PageTemplate";

const Vets = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    <PageTemplate title="Participating Veterinarians">
      {/* Instructions */}
      <List>
        <ListItem>
          <ListItemText>
            Please see the "Vet Forms" folder for authorization forms.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Vets who will see ferals that are sick/injured have a note in the
            last column. Please always inform them that the cat is feral when
            making the appointment.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            We stock antibiotics, eye drops, dewormer, antifungal medications,
            first aid items, anti-diarrheal meds, supportive care supplements,
            and more. If your foster is ill but not in an emergency situation,
            please contact the Foster Home Coordinators. Check the Foster Packet
            and Veterinary Care documents for what is available.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            All fosters should receive FVRCP and dewormer through volunteers.
            Please request through the Medication Requests portal.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            If your foster has an emergency, such as labored breathing, low or
            high temperature, seizure, etc and needs immediate care, email{" "}
            <Link
              href="mailto:fosterhomecoordinators@straycatblues.org"
              target="_blank"
              rel="noopener"
            >
              fosterhomecoordinators@straycatblues.org
            </Link>{" "}
            and{" "}
            <Link
              href="mailto:board@straycatblues.org"
              target="_blank"
              rel="noopener"
            >
              board@straycatblues.org
            </Link>{" "}
            and take them to your nearest veterinarian.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            At time of spay/neuter, all fosters should receive the surgery, a
            rabies vaccine if they are over 3lbs, FIV/FeLV (“snap”) test and
            HomeAgain microchip. Most vet partners stock the tests and chips for
            us but you may be asked to bring a microchip with you (such as to
            Delaware SPCA). We <b>must</b> use our microchips so the cats can be
            traced back to our rescue if they are lost or abandoned.
          </ListItemText>
        </ListItem>
      </List>

      {/* Vets Table */}
      {isLoading && <Loading />}
      <iframe        title="Table with participant vets information (like address, type of treatments offered, etc)"
        width="100%"
        height="1000"
        frameBorder="0"
        onLoad={() => setIsLoading(false)}
        src="https://docs.google.com/document/d/e/2PACX-1vTC3ARns-lzu2YkAGiILeZ53oJ0G5DApLDsrL0_OBhSNb6KS_j0bEnEmSQoOYvMDjPQIgeZeDrU5eK5/pub?embedded=true"></iframe>
    </PageTemplate>
  );
};

export default Vets;
