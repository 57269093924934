import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";
import PageTemplate from "../components/PageTemplate";

const CALENDARS = {
  "EMRT On-Call Calendar": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_a40444f7c58b81bd1c5b9e77fe8b8afabf16eba281a651c956702dcb6ed63992%40group.calendar.google.com&ctz=America%2FNew_York",
  },
  "Events Calendar": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_vts7evhuu2af6afuh06fiipqok%40group.calendar.google.com&ctz=America%2FNew_York"
  },
  "PSC Reservations Calendar": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_rbtln1v9m7tfjvh0pb6sgb9acg%40group.calendar.google.com&ctz=America%2FNew_York",
  },
  "PSNW Reservation Calendar": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_p6pm8gfrg4qrm73aj4luabof9o%40group.calendar.google.com&ctz=America%2FNew_York",
  },
  "PSC Cage Cleaners": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_p9s7l5pl95s3k2dkdu80rkqa0g%40group.calendar.google.com&ctz=America%2FNew_York",
  },
  "PSNW Cage Cleaners": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_m6u9pn7apuf918fqceoc9vu4gs%40group.calendar.google.com&ctz=America%2FNew_York",
  },
  "WWS Calendar": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_jgm5o0t27e01nfmj7iv1t3r2ck%40group.calendar.google.com&ctz=America%2FNew_York",
  },
  "WWS Virtual Clubhouse": {
    embeddingLink: "https://calendar.google.com/calendar/embed?src=c_iukpn4l73nv2ejnogvsqjpbb74%40group.calendar.google.com&ctz=America%2FNew_York",
  },
};

const Calendars = () => {
  return (
    <PageTemplate title="Calendars">
      {/* Calendar Accordions */}
        <Grid container>
          {Object.entries(CALENDARS).map(([name, { embeddingLink }], index) => (
            <Grid item xs={12} key={index}
              style={{ padding: '10px' }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`calendar-${index}-panel`}
                  id={`calendar-${index}-summary`}
                >
                  <Typography>{name}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    '& .MuiAccordionDetails-root': {
                      display: 'flex', 'flex-direction': 'column', padding: '8px 16px 16px',
                    },
                  }}
                >
                  <iframe title={name} src={embeddingLink} width="800" height="600" frameBorder="0" scrolling="no"/>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
    </PageTemplate>
  );
}

export default Calendars;
