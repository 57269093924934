import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Image from "material-ui-image";
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Carousel from "react-material-ui-carousel";
import { DeleteButton } from "../components/Editing";
import CloudinaryButton from "../components/photoUploader";

const useStyles = makeStyles((theme) => ({
  carousel: {
    width: "100%",
    "& img": {
      width: "100%",
      maxHeight: "500px",
      objectFit: "scale-down",
    },
    "& div": {
      maxHeight: "500px",
      marginBottom: "15px",
    },
  },
  paper: {
    padding: theme.spacing(0),
  },
  fit: {
    padding: theme.spacing(4),
  },
  margins: {
    margin: theme.spacing(1),
  },
}));
function Indicators(props) {
  const classes = props.classes;
  const IndicatorIcon =
    props.IndicatorIcon !== undefined ? (
      props.IndicatorIcon
    ) : (
      <FiberManualRecordIcon size="large" className={classes.indicatorIcon} />
    );
  let indicators = [];
  for (let i = 0; i < props.length; i++) {
    const className =
      i === props.active
        ? `${classes.indicator} ${props.indicatorIconButtonProps.className} ${classes.active} ${props.activeIndicatorIconButtonProps.className}`
        : `${classes.indicator} ${props.indicatorIconButtonProps.className}`;

    const style =
      i === props.active
        ? Object.assign(
            {},
            props.indicatorIconButtonProps.style,
            props.activeIndicatorIconButtonProps.style
          )
        : props.indicatorIconButtonProps.style;

    const item = (
      <IconButton
        key={i}
        className={className}
        style={style}
        onClick={() => {
          props.press(i);
        }}
        size="small"
      >
        {<IndicatorIcon key={i} {...props} />}
      </IconButton>
    );

    indicators.push(item);
  }
}

function Entry(props) {
  const classes = useStyles();
  const addImage = (props) => {
    return <AddIcon />;
  };
  return (
    <div>
      {props.initialValues.images && props.initialValues.images[0] && (
        <Accordion elevation={5}>
          <AccordionSummary>
            <Carousel
              navButtonsAlwaysVisible={props.initialValues.images.length > 1}
              navButtonsAlwaysInvisible={props.initialValues.images.length <= 1}
              fullHeightHover={true}
              animation={"slide"}
              className={classes.carousel}
              autoPlay={false}
              interval={12000}
              indicators={true}
              renderCustomControls={addImage}
              navButtonsProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0.26)",
                },
              }}
            >
              {props.initialValues.images.map((item) => (
                <CarouselImage key={item} item={item} />
              ))}
            </Carousel>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justify="center">
              <CloudinaryButton
                icon={<AddIcon fontSize="small" />}
                className={classes.margins}
              />
              <DeleteButton />
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

function CarouselImage(props) {
  const baseURL = "https://res.cloudinary.com/stray-cat-blues/image/upload/";
  return (
    <Image
      style={{ maxHeight: "500px", objectFit: "contain" }}
      src={
        baseURL +
        "w_1000,h_500,c_mpad,q_auto,f_auto/" +
        props.item.split(baseURL)[1]
      }
    />
  );
}

function ThumbnailImage(props) {
  const baseURL = "https://res.cloudinary.com/stray-cat-blues/image/upload/";
  return (
    <Icon>
      {props.key && (
        <Image
          style={{ objectFit: "contain" }}
          src={
            baseURL +
            "h_100,c_thumb,q_auto,f_auto/" +
            props.items[props.key].split(baseURL)[1]
          }
        />
      )}
    </Icon>
  );
}

function renderLeftNav(onClick, disabled) {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <ChevronLeftIcon />
    </IconButton>
  );
}
function renderRightNav(onClick, disabled) {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <ChevronRightIcon />
    </IconButton>
  );
}

function renderFullscreenButton(onClick, isFullscreen) {
  return (
    <IconButton
      onClick={onClick}
      className={`image-gallery-fullscreen-button${
        isFullscreen ? " active" : ""
      }`}
    >
      {isFullscreen && <FullscreenExitIcon />}
      {!isFullscreen && <FullscreenIcon />}
    </IconButton>
  );
}

export default function PhotoGalleryDisplay(props) {
  function renderThumbInner(item) {
    const { onThumbnailError } = props;
    const handleThumbnailError = onThumbnailError || props.handleImageError;

    return (
      <div className="image-gallery-thumbnail-inner">
        <img
          className="image-gallery-thumbnail-image"
          style={{ maxHeight: "100px", objectFit: "contain" }}
          src={item.thumbnail}
          alt={item.thumbnailAlt}
          title={item.thumbnailTitle}
          onError={handleThumbnailError}
        />
        {item.thumbnailLabel && (
          <div className="image-gallery-thumbnail-label">
            {item.thumbnailLabel}
          </div>
        )}
      </div>
    );
  }
  function renderAddButton() {
    return (
      <CloudinaryButton
        iconStyle={{ color: "white" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          paddingBottom: "1%",
          paddingRight: "10%",
          zIndex: 4,
          filter: "drop-shadow(0 2px 2px #1a1a1a)",
        }}
        icon={true}
        photos={props.images}
        setPhotos={props.setPhotos}
      />
    );
  }
  return (
    <div>
      {props.images && (
        <ImageGallery
          renderCustomControls={renderAddButton}
          items={props.images.map((image) => ({
            original: image,
            thumbnail: image,
          }))}
          showPlayButton={false}
          renderThumbInner={renderThumbInner}
          showFullscreenButton={props.images.length > 0}
        />
      )}
    </div>
  );
}
