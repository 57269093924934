import React from "react";
import Typography from "@material-ui/core/Typography";
import catEmpty from "../assets/catEmpty.svg";
import catError from "../assets/catEggs.svg";
import catMagnify from "../assets/catMagnify.svg";

export const Empty = (props) => (
  <div className="spinner" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
    <img src={catEmpty} alt="Error" />
    <Typography>{"There's nothin' in there!"}</Typography>
  </div>
);

export const Error = (props) => (
  <div className="spinner" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
    <img src={catError} alt="Error" />
    <Typography>{props.error}</Typography>
  </div>
);

export const Magnify = (props) => (
  <div className="spinner" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
    <img src={catMagnify} alt="Error" />
    <Typography>{props.error}</Typography>
  </div>
);
